import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import services from 'assets/recruitment.jpg'

import publicArea from 'assets/services/public.jpg'
import laundry from 'assets/services/laundry.jpg'
import room from 'assets/services/room-cleaning.jpg'
import gardening from 'assets/services/gardening.jpg'
import pool from 'assets/services/pool.jpg'

import electrical from 'assets/services/electrical.jpg'
import masonry from 'assets/services/masonry.jpg'
import painting from 'assets/services/painting.jpg'

import Dishwashing from 'assets/services/Dishwashing.jpg'
import equipment from 'assets/services/equipment.jpg'
import floor from 'assets/services/floor.jpg'
import trash from 'assets/services/trash.jpg'

import store from 'assets/services/store.jpg'




import { Stack, Typography, Box } from '@mui/material';
import BloodtypeOutlinedIcon from '@mui/icons-material/BloodtypeOutlined';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';

import './style.css'


export default function BasicGrid() {
    return (


        <Container>
            <Grid container spacing={8}>

                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ fontWeight: 700 }}>Hotel & Restaurant Services</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Housekeeping</Typography>
             
                    <div class="gallery-wrap">
                        <div class="item" style={{ backgroundImage: `url(${room})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Room cleaning</Typography>
                                {/* <Typography variant='caption' className='description'>
                                    Ammaran Hospitality Services (AHS) conducts monthly audits to uphold the highest service standards. This rigorous evaluation ensures our commitment to impeccable quality, compliance, and continuous improvement, guaranteeing an exceptional experience for our clients.
                                </Typography> */}
                            </div>

                        </div>
                        <div class="item" style={{ backgroundImage: `url(${laundry})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Laundry</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${publicArea})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Public Area cleaning</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${gardening})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Gardening</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${pool})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Pool cleaning</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>


                
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Engineering</Typography>
               
                    <div class="gallery-wrap">
                      
                      
                        <div class="item" style={{ backgroundImage: `url(${electrical})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Electrical</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${masonry})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Masonry</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${painting})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Painting</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>


                
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Kitchen Stewarding & Storekeeping
</Typography>
               
                    <div class="gallery-wrap">
                      
                      
                        <div class="item" style={{ backgroundImage: `url(${Dishwashing})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Dishwashing</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${equipment})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Kitchen Equipment Cleaning</Typography>
                            </div>
                        </div>
                        {/* <div class="item" style={{ backgroundImage: `url(${floor})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Floor Care</Typography>
                            </div>
                        </div> */}
                        <div class="item" style={{ backgroundImage: `url(${trash})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Trash and Waste Management</Typography>
                            </div>
                        </div>
                        <div class="item" style={{ backgroundImage: `url(${store})` }}>
                            <div class="overlay">
                                <Typography variant='h6'>Storekeeping</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>



            </Grid>
        </Container>
    );
}
