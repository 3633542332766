
// import { useState, useEffect } from 'react';
// import { useHover, useGesture } from '@use-gesture/react'
import { styled } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import sitout from 'assets/discussion.jpg'

import img1 from 'assets/box/AHS.jpg'
import img2 from 'assets/box/CASAH.jpg'
import img3 from 'assets/box/amaaran-enterprises.jpg'

import Container from '@mui/material/Container';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';
const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
    overflow: 'hidden',
    margin: '3rem 3rem',
    // boxShadow: '0 0 200px rgba(0, 0, 0, 0.9) inset',
    '& .MuiImageListItemBar-root': {
        background: 'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: 'all 0.275s ease-in-out,visibility 0s 0.275s',
        visibility: 'hidden',
        willChange: 'transform',
        transform: 'translateX(100%)',
        width: '80%',
        '&:before': {
            position: 'absolute',
            top: '50%',
            transform: 'translateX(1%) translateY(-50%)',
            right: '100%',
            content: '""',
            width: 0,
            height: 0,
            // borderStyle: 'solid',
            // borderWidth: '25rem 4rem 25rem 0',
            // borderColor: 'transparent rgba(0, 0, 0, 0.8) transparent transparent',
            overflow: 'hidden'
        },
        '& .MuiImageListItemBar-titleWrap': {
            transform: 'translateX(-20px)',
        },
        '& .MuiImageListItemBar-title': {
            overflow: 'visible',
            fontSize: '1.5rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: '.1em',
            // textShadow: '0px 0px 10px rgb(0 0 0 / 50 %)',
            marginBottom: theme.spacing(1),
            position: 'relative',
            top: '0px',
            transition: '.1s ease-in-out',
            transform: 'translateX(-10px)',
        },
        '& .MuiImageListItemBar-subtitle': {
            whiteSpace: 'normal',
            transform: 'translateX(-10px)',
            lineHeight: '1.5em',
            paddingLeft:'20px',
        }

    },
    '& .MuiImageListItem-img': {
        transition: '.5s',
        transformOrigin: 'bottom right',
        filter: 'grayscale(0)',
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        // border: 'rgb(255 255 255 / 30%) solid 2px',
        top: '4px',
        left: '4px',
        right: '4px',
        bottom: '4px',
        opacity: 1,
        transitionDuration: '0.3s',
        transitionProperty: 'opacity border',
        zIndex: 1,
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        opacity: 1,
        zIndex: 1,
    },
    '&:hover': {
        '& .MuiImageListItemBar-root': {
            // transform: 'none',
            visibility: 'visible',
            transitionDelay: '0s',
            transform: 'translateX(30%) translateY(0%)',
            '&:after': {
                transform: 'translateX(0)',
                opacity: 1
            }
        },
        '& .MuiImageListItem-img': {
            transform: 'scale(1.004)',
            filter: 'grayscale(1)'
        },
        '&:before': {
            top: '-3px',
            left: '-3px',
            right: '-3px',
            bottom: '-3px',
        }
    }
}))


const FeaturedPortfolio = () => {

    const portfolioItems = [
        {
            title: 'Project 1',
            imageSrc: 'path/to/image1.jpg',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
            title: 'Project 2',
            imageSrc: 'path/to/image2.jpg',
            description: 'Nullam consequat eros at consequat lacinia.',
        },
        // Add more portfolio items as needed
    ];
    const matches = useMediaQuery('(min-width:1024px)');

    return (
        <section className="featured-portfolio">
            <div className="portfolio-items">


                <ImageList
                    variant="quilted"
                    cols={matches ? 15 : 15}
                    rowHeight={matches ? 80 : 120}
                    gap={0}
                    sx={{ margin: 0, padding: 0, pt: 5 }}
                >
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img1} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='AHS'
                            subtitle={<p>
                                At Amaaran Hospitality Services, we are dedicated to crafting unforgettable experiences for you, your family, and your guests. Our passion lies in the art of hospitality, and we take pride in curating exceptional moments that turn your dreams into reality.

                            </p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img2} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='Casah'
                            subtitle={<p>
                                Discover a world of luxury, comfort, and impeccable service as we redefine your expectations of hospitality. Whether you're seeking a relaxing getaway, planning a special event, or looking for corporate solutions, Amaaran Hospitality Services is your trusted partner.
                            </p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img3} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='Title goes here'
                            subtitle={<p>
                                Some content and description goes here</p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img2} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='Title goes here'
                            subtitle={<p>
                                Some content and description goes here</p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img3} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='Title goes here'
                            subtitle={<p>
                                Some content and description goes here</p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>
                    <StyledImageListItem cols={matches ? 5 : 15} rows={matches ? 4 : 1}

                    >
                        <img src={img1} alt='education' loading="lazy" style={{ backgroundPosition: 'bottom' }} />
                        <ImageListItemBar
                            title='Title goes here'
                            subtitle={<p>
                                Some content and description goes here</p>}
                            sx={{ height: '100%', textAlign: 'right', fontWeight: 300, letterSpacing: '.15rem' }}
                        />
                    </StyledImageListItem>


                </ImageList>

            </div>
        </section>
    );
};
export default FeaturedPortfolio;