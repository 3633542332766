import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import services from 'assets/recruitment.jpg'
import { Stack, Typography, Box } from '@mui/material';
import BloodtypeOutlinedIcon from '@mui/icons-material/BloodtypeOutlined';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';


export default function BasicGrid() {
  return (
    <>

      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: 700 }}>CONTRACT SERVICES</Typography>

            <Typography variant="h6" gutterBottom>What makes AHS unique?</Typography>
          </Grid>
          <Grid container item xs={6} spacing={4}>


            <Grid item xs={12}>
              <Typography variant="h5" color="primary">Daily Excellence Assurance</Typography>
              <Typography>We commit to daily tracking to ensure exceptional hospitality services.
                Our real-time monitoring and data analysis empower us to maintain impeccable quality, adapt swiftly,
                and offer you an unmatched experience. We prioritize your satisfaction every day.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" color="primary">Your Voice, Our Progress</Typography>
              <Typography>We value your input. Our weekly feedback process enables us to continually refine our services based on your insights.
                Together, we create an even more exceptional hospitality experience.</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" color="primary">Monthly Excellence Assessment</Typography>
              <Typography>We conducts monthly audits to uphold the highest service standards.
                This rigorous evaluation ensures our
                commitment to impeccable quality, compliance, and continuous improvement, guaranteeing an exceptional experience for our clients..</Typography>
            </Grid>

            {/* <Grid item xs={3}>                
              <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <HotelOutlinedIcon sx={{ fontSize: '15rem', color: 'primary.main' }} />
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Stack direction={'column'} sx={{ p: 5,height:'100%' }} justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="h5" >Hotels & Resorts</Typography>
                  <Typography variant="caption"  >All Departments</Typography>
                </Stack>
              </Grid> */}

          </Grid>




          <Grid item xs={6}>
            <List>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Service quality is never compromised" />
              </ListItem>

              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Statutory payments track record is impeccable" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="All employees are groomed as per 5-star hotel standards" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Experienced and trained employees" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Monthly audit and weekly feedback process" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Operations are monitored on a daily basis" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="We provide both contractual and on-demand services" />
              </ListItem>
              <ListItem >
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="We use the best available chemicals and follow the best practices in the industry for our operations " />
              </ListItem>

            </List>
          </Grid>




        </Grid>
      </Container>
    </>
  );
}
