import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
// import { images } from "./image-data";
import slider1 from 'assets/slider2/Rising Sun - Slide 1.jpg'
import slider2 from 'assets/slider2/Skyscpe - Slide 2.jpg'
import slider3 from 'assets/slider2/Deals - Slide 3.jpg'
import slider4 from 'assets/slider2/Team Work - Slide 4.jpg'
import slider5 from 'assets/slider2/Scaling Heights - Slide 5.jpg'

const images = [
    slider1,
    slider2,
    slider3, slider4, slider5
];

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export const Slider = () => {
    const [[page, direction], setPage] = useState([0, 0]);

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };
    // Add useEffect to handle auto-animation
    React.useEffect(() => {
        const interval = setInterval(() => {
            paginate(1); // Automatically navigate to the next slide
        }, 5000); // Change slide every 5 seconds (adjust as needed)

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, [page]); // Run this effect whenever the page changes

    return (
        <>
            <div style={{ display: "none"}}>
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`preload-${index}`} />
                ))}
            </div>
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                />

            </AnimatePresence>

        </>
    );
};
