import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import services from 'assets/recruitment.jpg'
import { Stack, Typography, Box } from '@mui/material';
import BloodtypeOutlinedIcon from '@mui/icons-material/BloodtypeOutlined';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';
export default function BasicGrid() {
  return (
    <>
      <Container maxWidth={true}
        sx={{
          flexGrow: 1,
          display: 'flex',
          backgroundImage: `url(${services})`,
          // minHeight: '30vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          // overflow: 'hidden',
          position: 'relative',
          px: '0!important',
          mb:20
        }}>
        <Box maxWidth={true} sx={{
          px: 0, m: 0,
          flexGrow: 1,
          display: 'flex',
          backgroundImage: `linear-gradient(
                to bottom ,  
                transparent 0%,
                rgba(255, 255, 255, 0.7) 20%, 
                rgba(255, 255, 255) 100%
              )`
        }}>
          <Container sx={{ position: 'relative', zIndex: 4,textAlign:'center' }}>
            <Grid container>
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  background: 'white',
                  p: 2,
                  boxShadow: '0 7px 20px rgba(100, 28, 2, 0.135)',
                  position: 'relative',
                  zIndex: 5,
                  bottom: 40
                }}>
                <Typography variant="h4">RECRUITMENT SERVICES</Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <HotelOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Hotels & Resorts</Typography>
                  <Typography variant="caption"  >All Departments</Typography>
                </Stack>
              </Grid>
              
              <Grid item xs={3}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <FoodBankOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Independent Restaurants & Bars</Typography>
                  <Typography variant="caption"  >All Departments</Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={3}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <StorefrontOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Supermarket Chains</Typography>
                  <Typography variant="caption">Admin, Finance and <br/>Management Departments</Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={3}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <BloodtypeOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Hospitals</Typography>
                  <Typography variant="caption"  >Admin, Finance and <br/> Managements Departments</Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={4}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <BusinessOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Business Centres</Typography>
                  <Typography variant="caption"  >Admin, Finance and Managements Departments</Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={4}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <CorporateFareOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >Malls</Typography>
                  <Typography variant="caption"  >Admin, Finance, Sales and Managements Departments</Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={4}>
                <Stack direction={'column'} sx={{ p: 5 }} justifyContent={'center'} alignItems={'center'}>
                  <ScreenshotMonitorOutlinedIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
                  <Typography variant="h5" >IT</Typography>
                  <Typography variant="caption"  >Admin, Finance and Managements Departments</Typography>
                </Stack>
              </Grid>

            </Grid>
          </Container>
        </Box>
      </Container>
    </>
  );
}
