import * as React from 'react';
import './style.css'
import { Grid, Typography, Container } from '@mui/material';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import { fadeInVariants } from 'animationVariants';
import {motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function BasicGrid() {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    });

    return (
        <Container sx={{ flexGrow: 1, textAlign: 'center', py: 2 }}>
            <Grid container spacing={4}   ref={ref}>

                <Grid item xs={12}>
                    <Typography variant="h1" gutterBottom color="primary.main">SERVICES</Typography>
                </Grid>

                <Grid item xs={4} component={motion.div}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={fadeInVariants}
                    transition={{ duration: .5, ease: 'easeOut' }} 
                >
                    <div className='c-services__item'>
                        <BadgeOutlinedIcon sx={{ fontSize: '4rem' }} />
                        <h3>RECRUITMENT SERVICES</h3>
                        <p>We specialize in unlocking the untapped potential of the hospitality sector by connecting businesses with the industry's most seasoned, skilled, and exceptional talent.</p>

                    </div>
                </Grid>

                <Grid item xs={4} component={motion.div}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={fadeInVariants}
                    transition={{ duration: .5, ease: 'easeOut' }}
                >
                    <div className='c-services__item'>
                        <HandshakeOutlinedIcon sx={{ fontSize: '4rem' }} />
                        <h3>CONTRACTING SERVICES</h3>
                        <p>Elevate Your Business with Our Expertise: We specialize in managing outsourced departments for 5-star hotels and premium commercial spaces.</p>

                    </div>
                </Grid>

                <Grid item xs={4} component={motion.div}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={fadeInVariants}
                    transition={{ duration: .5, ease: 'easeOut' }}
                >
                    <div className='c-services__item'>
                        <HomeRepairServiceOutlinedIcon sx={{ fontSize: '4rem' }} />
                        <h3>ON-DEMAND SERVICES</h3>
                        <p>Elevate Your Workforce on Your Terms: Exclusive to Our Contracted Clients, we offer skilled personnel on a daily, weekly, and monthly basis, whenever you need them.</p>

                    </div>
                </Grid>
            </Grid>

        </Container>
    );
}
