import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import services from 'assets/recruitment.jpg'
import { Stack, Typography, Box } from '@mui/material';
import Section1 from './Section1'
import AboutUs from './AboutUs'
import Footer from '../Footer'
import { Slider } from './Slider'
import Services from './Services'
import Card from './Card'
import Recruitment from './Recruitment'
import Contract from './Contract'
import Hotel from './ImagesGalleries/Hotel'

import ScrollToTop from 'Components/ScrollToTop'

import ahs from 'assets/box/AHS.jpg'
import casah from 'assets/box/CASAH.jpg'
import amaaran from 'assets/box/amaaran-enterprises.jpg'

import ahsLogo from 'assets/ahs-logo/0.5x/Asset 1@0.5x.png'
import casahLogo from 'assets/casah-logo/0.5x/Asset 2@0.5x.png'
import amaaranLogo from 'assets/amaaran-logo/0.5x/Asset 1@0.5x.png'


const ImgGrid1 = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${ahs})`,
  backgroundSize: 'cover',
  backgroundPosition: 'right',
  height: '40vh',
  '&:after': {
      display: 'block',
      content: '""',
      width: 'calc(100% - 30px)',
      height: '100%',
      position: 'relative',
      top: '15px',
      left: '15px',
      border: `2px solid white`,
  },

}));


const ImgGrid2 = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${casah})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '40vh',
  '&:after': {
      display: 'block',
      content: '""',
      width: 'calc(100% - 30px)',
      height: '100%',
      position: 'relative',
      top: '15px',
      left: '15px',
      border: `2px solid white`,
  },

}));

const ImgGrid3 = styled(Grid)(({ theme }) => ({
  
  backgroundImage: `url(${amaaran})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '40vh',
  '&:after': {
      display: 'block',
      content: '""',
      width: 'calc(100% - 30px)',
      height: '100%',
      position: 'relative',
      top: '15px',
      left: '15px',
      border: `2px solid white`,
  },

}));

export default function BasicGrid() {
  return (
    <>
        <div id="back-to-top-anchor" style={{ minHeight: '0px!important', height: 0 }} ></div>
      <Container maxWidth={true} sx={{ p: '0!important' }} >
        <Grid container spacing={2} className='banner-img'
          sx={{ flexGrow: 1, display: 'flex', position: 'relative' }}>
          <Grid item className="example-container"  >
            <Slider />

          </Grid>

          {/* <Grid item sx={{zIndex:4,position:'absolute',alignSelf:'center',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center'}}>
            <Typography variant="h1" sx={{color:'white'}}>AMAARAN ENTERPRISES</Typography>
            <Typography variant="h5" sx={{color:'white',fontWeight:200}}>We have a panel of hospitality professionals working full time as well as consultants with a combined experience of more than 50+ years.</Typography>       
       </Grid> */}

          <Grid item container spacing={2}
            sx={{
              zIndex: 4,
              position: 'absolute',
              justifyContent: 'flex-end', // Align vertically to the bottom
              alignItems: 'flex-end', // Align horizontally to the right
              bottom: 17, /* Push it to the bottom */
              right: 0/* Push it to the right */,
              height: '100vh',
              pr: 10,
                  backgroundImage: `linear-gradient(
                to bottom right,  
                transparent 0%,
                rgba(12, 13, 19, 0.5) 50%, 
                rgba(12, 13, 19) 100%
              )`,
          //     backgroundImage: `linear-gradient(
          //   to bottom right,  
          //   transparent 0%,
          //   rgba(255, 255, 255, 0.5) 50%, 
          //   rgba(255, 255, 255) 100%
          // )`
            }}>
            <Grid item xs={2} sx={{ px: 2, mb: 4 }}>
              {/* <ImgGrid1/> */}
              <Card image={ahs} logo={ahsLogo} title={'AHS'} content={'At Amaaran Hospitality Services, we are dedicated to crafting unforgettable experiences for you, your family, and your guests. Our passion lies in the art of hospitality, and we take pride in curating exceptional moments that turn your dreams into reality.'} />
            </Grid>

            <Grid item xs={2} sx={{ px: 2, mb: 4 }}>
              {/* <ImgGrid2/> */}
              <Card image={casah} logo={casahLogo} title={'CASAH'} content={`Discover a world of luxury, comfort, and impeccable service as we redefine your expectations of hospitality. Whether you're seeking a relaxing getaway, planning a special event, or looking for corporate solutions, Amaaran Hospitality Services is your trusted partner.`} />
            </Grid>

            <Grid item xs={2} sx={{ px: 2, mb: 4 }}>
              {/* <ImgGrid3/> */}
              <Card image={amaaran} logo={amaaranLogo} title={'AMAARAN ENTERPRISE'} content={`Amaaran Enterprise is your trusted source for top-tier trading solutions. With a reputation built on reliability and innovation, we offer comprehensive services for businesses and individuals. Explore a world of limitless opportunities and redefine your success with us.`} />
            </Grid>
          </Grid>


          <Grid item>
          </Grid>
        </Grid>



      </Container>

      {/* <Container className='banner-img' maxWidth={true} 
    sx={{ flexGrow: 1,display:'flex',backgroundImage:`url(${services})`,minHeight:'60vh',backgroundSize:'cover',
    backgroundPosition:'center',
    overflow: 'hidden', 
    position: 'relative', }}>
    
      <Grid container spacing={2} direction={'column'} alignItems={'center'} justifyContent={'center'} 
      sx={{zIndex:4,position:'absolute',height:'100%',alignSelf:'center'}}>
        <Grid item>
            <Typography variant="h1" sx={{color:'white'}}>AMAARAN ENTERPRISES</Typography>
       </Grid>
        <Grid item>
            <Typography variant="h5" sx={{color:'white',fontWeight:200}}>We have a panel of hospitality professionals working full time as well as consultants with a combined experience of more than 50+ years.</Typography>
        </Grid>
      </Grid>
    </Container> */}
      {/* <Container maxWidth={true} sx={{ my: 5 }}>
        <Section1 />
      </Container> */}

      <Container maxWidth={true} sx={{ backgroundColor: theme => theme.palette.primary.main, position:'relative',bottom:'2rem'}}>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center', px: 65, py: 8, color: 'white' }}>
            <Typography variant='h5'sx={{fontWeight:100,letterSpacing:'1.5px'}}>
              <i>"AHS is here for you every day, offering a versatile suite of services<br/> to a diverse group of companies"</i>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={true} sx={{ my: 20 }}>
        <Services />
      </Container>



      <Recruitment />

      <Contract />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />
      <Hotel />
      <Container maxWidth={true} sx={{ my: 5 }}>
        <AboutUs />
      </Container>
      <Footer />

      <ScrollToTop/>
    </>
  );
}
