import './App.css';
import Navbar from 'Components/Navbar'

import { ThemeProvider } from '@mui/material/styles'
import classicLight from 'theme/classicLight'

import Home from 'Module/Home'

function App() {
  return (
    <ThemeProvider theme={classicLight}>
      <Navbar/>
      <Home/>
    </ThemeProvider>
  );
}

export default App;
