import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function BasicGrid() {
  return (
    <Container sx={{ flexGrow: 1,textAlign:'center',py:20}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom color="primary.main">ABOUT US</Typography>
          <Typography variant="h4" gutterBottom>FOUNDED IN 2014</Typography>
          <Typography variant="body1">
          We have a panel of hospitality professionals working full time as well as consultants with a
combined experience of more than 50+ years.
We are a specialized hospitality service provider who cater to 5-star hotels, premium office
spaces, office complexes, apartment buildings, hospitals, public sector companies, factories and
plants, car showrooms and business centres.
          </Typography>
          {/* <br/>
          <Typography variant="h4" gutterBottom>VISION</Typography>
          <Typography variant="body1">
          We strive to be the most efficient and effective service provider thereby ensuring smooth and
hassle free functioning all year round for all our clientele.
          </Typography> */}
        </Grid>
      </Grid>
    </Container>
  );
}
