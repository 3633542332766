
// import { useState, useEffect } from 'react';
// import { useHover, useGesture } from '@use-gesture/react'
import { styled } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import sitout from 'assets/discussion.jpg'

import img1 from 'assets/box/AHS.jpg'
import img2 from 'assets/box/CASAH.jpg'
import img3 from 'assets/box/amaaran-enterprises.jpg'

import Container from '@mui/material/Container';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';
import './card.css'

const FeaturedPortfolio = ({image,title,content,logo}) => { 

    return (
        <div class="card">
          <div class="wrapper">
            <img src={image} class="cover-image" />
          </div>
          {/* <Typography variant='h1'  class="title">{title}</Typography> */}
          <Typography  variant='body2' class="character">{content}</Typography>
          <img src={logo} class="title" />
          {/* <img src="https://ggayane.github.io/css-experiments/cards/dark_rider-character.webp" class="character" /> */}
        </div>
    );
};
export default FeaturedPortfolio;